import React, { useState } from "react"
import { FaCircleNotch } from "react-icons/fa"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Toggle from "react-toggle"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import axios from "axios"
import ContactSchema from "../helpers/contact-schema"
import { Formik, Form, Field, ErrorMessage } from "formik"

const Footer = ({ siteTitle, socialLink, logo }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [response, setResponse] = useState("")
  const [creatingLeadAjax, setCreatingLeadAjax] = useState(false)

  return (
    <footer>
      <div className="containers">
        <div className="first-block">
          <Link to="/" className="header-link">
            <img src={logo} alt={siteTitle} />
          </Link>
          <div className="social-media-links-container">
            <a href={socialLink.facebook}>
              <StaticImage src="../images/face-icon.png" alt={siteTitle} />
            </a>

            <a href={socialLink.instagram}>
              <StaticImage src="../images/instagram-icon.png" alt={siteTitle} />
            </a>

            <a href={socialLink.linkedin}>
              <StaticImage src="../images/linkedin-icon.png" alt={siteTitle} />
            </a>
          </div>
          <ul className="footer-links links">
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
          <div>
            <div className="copyright">
              © Copyright {new Date().getFullYear()}
            </div>
            <div className="copyright">
              Christie's International Real Estate Summit Colorado
            </div>
          </div>
        </div>
        <div className="second-block">
          <div>
            <div>323 N. Main Street</div>
            <div>Breckenridge, CO 80424</div>
            <div>
              <br />
            </div>
            <div>Mailing: PO Box 4868, Breckenridge, CO 80424</div>
          </div>
          <div>
            <div>P: +1 970.344.9002</div>
            <div>info@christiessummitcore.com</div>
          </div>

          {/* <div className="toggle-container">
        <Toggle
          defaultChecked={true}
          icons={false}
          onChange={() => console.log("toggle")}
        />
        <p>
          Accessibility
        </p>
      </div> */}
        </div>
        <div className="third-block">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
            }}
            validationSchema={ContactSchema}
            onSubmit={async values => {
              setCreatingLeadAjax(true)

              if (!executeRecaptcha) {
                setResponse("Recaptcha Issue, Try Again Later...")
                setCreatingLeadAjax(false)

                return
              }

              try {
                const recaptchaToken = await executeRecaptcha("footer")
                await axios({
                  url: process.env.FOUNDATION_LEAD_CREATE_ENDPOINT,
                  method: "POST",
                  data: {
                    recaptchaToken,
                    formValues: values,
                    propertyInfo: {
                      createdFrom: `Summit Colorado Christies - Footer - ${siteTitle}`,
                    },
                  },
                })

                setResponse(
                  `Your contact was sent to us, we'll get in touch briefly with you!`
                )
              } catch (error) {
                setResponse(
                  `Sorry, there was an issue sending your contact request...`
                )
              }

              setCreatingLeadAjax(false)
            }}
          >
            {({ handleSubmit }) => (
              <Form
                onSubmit={handleSubmit}
                name="contact non exclusive"
                method="POST"
              >
                <div className="content">
                  <div className="content-1">
                    <div className="third-block-inner">
                      <Field name="firstName" placeholder="FIRST NAME" />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        render={msg => <div className="error">*</div>}
                      />
                      <Field name="lastName" placeholder="LAST NAME" />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        render={msg => <div className="error">*</div>}
                      />
                    </div>
                    <div className="third-block-inner">
                      <Field name="email" placeholder="EMAIL ADDRESS" />
                      <ErrorMessage
                        name="email"
                        component="div"
                        render={msg => <div className="error">*</div>}
                      />
                      <Field name="phone" placeholder="PHONE NUMBER" />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        render={msg => <div className="error">*</div>}
                      />
                    </div>
                  </div>
                  <div className="content-2">
                    <label>
                      <Field type="checkbox" name="haveBrokerContact" />
                      Please have a broker contact me
                    </label>
                    <label>
                      <Field
                        type="checkbox"
                        name="interestedInBuyingOrSelling"
                      />
                      I am interested in buying or selling a property
                    </label>
                  </div>
                </div>
                <div>
                  <button
                    aria-disabled={creatingLeadAjax}
                    disabled={creatingLeadAjax}
                    type="submit"
                    className="submit-btn"
                  >
                    {creatingLeadAjax ? (
                      <FaCircleNotch className="cha-cl__spin-icon" />
                    ) : (
                      "SUBMIT"
                    )}
                  </button>
                  {response && (
                    <span className="response-white">{response}</span>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="w-full max-w-screen-xl mx-auto px-2 text-center text-xs mt-8">
        Real estate website design, development and optimization by&nbsp;
        <a className="underline" href="http://www.organicreturn.com/">
          Organic Return
        </a>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
